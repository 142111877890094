export class UserRequisitesModel {
  bik = ''
  can_edit_requisites = false
  id = 0
  inn = ''
  kpp = ''
  law_form = 'juridical'
  name_full = ''
  requisites_updated_at = ''
  rs = null
}

export class UserModel {
  id? = 0
  accepted = false
  billing_plan_type = ''
  name = ''
  email = ''
  phone = ''
  roles: string[] = []
  requisites: UserRequisitesModel = new UserRequisitesModel()
}
