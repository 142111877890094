import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import {
  UserModel,
  UserRequisitesModel,
} from '@/modules/ProfilePage/models/UserModel'
import UserService from '@/modules/ProfilePage/UserService'

@Module({
  namespaced: true,
})
export default class UserStore extends VuexModule {
  private user: UserModel = new UserModel()
  private isLoginet = false

  @Mutation
  private setUser(user: UserModel) {
    this.user = user
    this.isLoginet = true
  }

  @Action({ commit: 'setUser' })
  getUser(): Promise<UserModel> {
    return UserService.getUser()
  }

  @Action
  updateUser(requisites: UserRequisitesModel): Promise<UserRequisitesModel> {
    return UserService.updateUser(requisites)
  }

  get currentUser(): UserModel {
    return this.user
  }

  get isAuth(): boolean {
    return this.isLoginet
  }
}
