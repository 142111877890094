import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { LoginModel } from '../../modules/AuthPage/models/LoginModel'
import { RegisterModel } from '@/modules/AuthPage/models/RegisterModel'
import AuthService from '../../modules/AuthPage/AuthService'

@Module({
  namespaced: true,
})
export default class AuthStore extends VuexModule {
  private isAuth = false

  @Mutation
  private setLoginUser() {
    this.isAuth = true
  }

  @Action({ commit: 'setLoginUser' })
  login(user: LoginModel): unknown {
    return AuthService.login(user)
  }

  @Action({ commit: 'setLoginUser' })
  register(user: RegisterModel): Promise<RegisterModel> {
    return AuthService.register(user)
  }
}
