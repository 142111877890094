import axios from '@/api/axios'

class PlaceTypesService {
  async getPlaceTypes(): Promise<any[]> {
    try {
      const response = await axios.get('/api/b2b/v1/place-types')
      return response.data.data
    } catch (error) {
      return error.response.data
    }
  }
}

export default new PlaceTypesService()
