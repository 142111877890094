import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import LocationsService from '@/core/services/LocationsService'
import { LocationsModel } from '@/core/models/LocationsModel'

@Module({
  namespaced: true,
})
export default class LocationsStore extends VuexModule {
  private locations: LocationsModel[] = []

  @Mutation
  private setLocations(locations: LocationsModel[]) {
    this.locations = locations
  }

  @Action({ commit: 'setLocations' })
  getLocations(): Promise<LocationsModel[]> {
    return LocationsService.getLocations()
  }

  get LocationsList(): LocationsModel[] {
    return this.locations
  }
}
