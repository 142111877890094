import axios from '@/api/axios'

class LocationsService {
  async getLocations(): Promise<any[]> {
    try {
      const response = await axios.get('/api/b2b/v1/locations')
      return response.data.data
    } catch (error) {
      return error.response.data
    }
  }
}

export default new LocationsService()
