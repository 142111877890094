






























































import { Component, Vue } from 'vue-property-decorator'

import BaseButton from '@/components/base/BaseButton.vue'
import LandingTitle from './LandingTitle.vue'

@Component({ name: 'LandingInfo', components: { LandingTitle, BaseButton } })
export default class LandingInfo extends Vue {
  list = [
    {
      id: 1,
      name: 'Просмотры ваших помещений',
      text: 'Количество просмотров страниц ваших помещений за все время размещения и последние полгода за каждый месяц.',
      icon: 'eye.svg',
    },
    {
      id: 2,
      name: 'Статистика звонков',
      text: 'Количество звонков по каждому помещению - вы можете видеть, интересно ли клиентам звонить вам после просмотра фото и описания, и что можно подкорректировать',
      icon: 'phone.svg',
    },
    {
      id: 3,
      name: 'Рейтинг и отзывы арендаторов',
      text: 'Обратная связь от клиентов - это важно для вашего роста!',
      icon: 'rating.svg',
    },
  ]
}
