var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',_vm._g(_vm._b({staticClass:"button",class:[
    {
      'button--icon': _vm.icon,
      'button--small': _vm.small,
      'button--medium': _vm.medium,
      'button--large': _vm.large,
      'button--fluid': _vm.fluid,
      'button--outline': _vm.outline && _vm.color === 'white',
      'button--gray': _vm.color === 'gray',
      'button--green': _vm.color === 'green',
      'button--purple': _vm.color === 'purple',
      'button--red': _vm.color === 'red',
    },
    _vm.classes ],attrs:{"type":_vm.type}},'button',Object.assign({}, _vm.$attrs,
    {disabled: _vm.isDisabled}),false),_vm.$listeners),[(_vm.$slots['icon-left'])?_c('span',{staticClass:"button__icon button__icon--left"},[_vm._t("icon-left")],2):_vm._e(),_vm._t("default"),(_vm.$slots['icon-right'])?_c('span',{staticClass:"button__icon button__icon--right"},[_vm._t("icon-right")],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }