import axios from '@/api/axios'
import { setItem } from '@/utils/persistanceStorage'
import { LoginModel } from './models/LoginModel'
import { RegisterModel } from './models/RegisterModel'

class AuthService {
  async login(credentials: LoginModel) {
    const response = await axios.post('/api/b2b/v1/auth/login', credentials)

    if (response.headers.authorization) {
      setItem('token', response.headers.authorization)
    }

    return response.data
  }

  async logout() {
    const response = await axios.post('/api/b2b/v1/auth/logout')
    console.log(response)

    localStorage.removeItem('token')
  }

  async register(credentials: RegisterModel): Promise<any> {
    try {
      return await axios.post('/api/b2b/v1/register', credentials)
    } catch (error) {
      return error.response.data
    }
  }

  async refreshToken() {
    try {
      const response = await axios.post('/api/b2b/v1/auth/refresh')
      console.log(response)
    } catch (error) {
      console.log(error)
    }
  }
}

export default new AuthService()
