import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import AuthStore from '@/store/modules/auth.module'
import UserStore from '@/store/modules/user.module'
import RoomsStore from '@/store/modules/rooms.module'
import RoomStore from '@/store/modules/room.module'
import StatististicsStore from '@/store/modules/statististics.module'
import PlaceTypesStore from '@/store/modules/place-types.module'
import LocationsStore from '@/store/modules/locations.module'

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    AuthStore,
    UserStore,
    RoomsStore,
    StatististicsStore,
    PlaceTypesStore,
    LocationsStore,
    RoomStore,
  },
})
