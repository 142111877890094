











































import { Component, Vue, Prop } from 'vue-property-decorator'
import { v4 as uuidv4 } from 'uuid'

type InputType = 'text' | 'number' | 'tel' | 'email' | 'password'

@Component({
  name: 'Field',
  inheritAttrs: false,
})
export default class BaseInput extends Vue {
  showPassword = false

  @Prop({ default: () => uuidv4() })
  id!: string

  @Prop()
  value!: unknown

  @Prop({ default: false })
  isShakeError!: boolean

  @Prop({ default: 'text' })
  type!: InputType

  @Prop()
  label!: string

  @Prop()
  classWrap!: string

  @Prop({ default: false })
  labelRequered!: boolean

  @Prop({ default: false })
  group!: boolean

  @Prop()
  errorText!: string

  @Prop({ default: false })
  hasError!: boolean

  @Prop({ default: false })
  disabled!: boolean

  get initType(): string {
    if (this.type === 'password') {
      return this.showPassword ? 'text' : 'password'
    }
    return this.type
  }
}
