























import { Component, Vue } from 'vue-property-decorator'
import LandingTitle from './LandingTitle.vue'
import { Filter, Site } from '@/api/CommonWrapper/types'

@Component({ name: 'LandingPlatform', components: { LandingTitle } })
export default class LandingPlatform extends Vue {
  list = [
    {
      id: 4,
      name: 'Dелиevent',
      text: 'Залы для мероприятий, Конференц-залы, Лофты, Фотостудии',
    },
    {
      id: 2,
      name: 'Dелиroom',
      text: 'Аудитории, Залы для лекций, Залы для мастер-классов, Залы для семинаров',
    },
    {
      id: 3,
      name: 'Dелиsport',
      text: 'Залы для йоги, Залы для танцев, Спортивный залы, Теннисные корты',
    },
    {
      id: 10,
      name: 'Dелиoffice',
      text: 'Переговорные, Рабочие места в коворкингах, Рабочие кабинеты',
    },
  ]

  sites: Site[] = []
  filters: Filter[] = []

  private async created() {
    this.sites = await this.$api.common.getSitesList()
    this.filters = await this.$api.common.getFiltersList()
  }
}
