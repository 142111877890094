import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import axios from '@/api/axios'

export interface FillingItemInterface {
  icon: string
  id: number
  name: string
}

export type ProhibitionItemInterface = FillingItemInterface

export interface Condition {
  id: null | number
  weekdays: []
  from: null | number
  to: null | number
  visitors: {
    min: null | number
    max: null | number
  }
  price: null | number
  fullday: false | boolean
}

export interface StepOneInterface {
  name: string
  placeType: string
  description: string
  capacity: null | number
  area: null | number
  address: string
  active: boolean
  show_schedule: boolean
}

export interface StepTwoInterface {
  integrations: {
    gCalendar: {
      calendarId: string
    }
    yClient: {
      apiToken: string
      login: string
      password: string
    }
    current: null | string
  }
}

export interface StepThreeInterface {
  filling: FillingItemInterface[]
  prohibitions: ProhibitionItemInterface[]
}

export interface StepFourInterface {
  videoUrl: string
  photos: string[] | File[]
}

export interface StepFiveInterface {
  conditions: Condition[]
}

export interface Model {
  StepOne: StepOneInterface
  StepTwo: StepTwoInterface
  StepThree: StepThreeInterface
  StepFour: StepFourInterface
  StepFive: StepFiveInterface
}

export interface Integration {
  id: number
  calendar_id: number
  places?: string[]
}

@Module({
  namespaced: true,
})
export default class LocationsStore extends VuexModule {
  private model: Model = {
    StepOne: {
      name: '',
      placeType: '',
      description: '',
      capacity: null,
      area: null,
      address: '',
      active: true,
      show_schedule: true,
    },
    StepTwo: {
      integrations: {
        gCalendar: {
          calendarId: '',
        },
        yClient: {
          apiToken: '',
          login: '',
          password: '',
        },
        current: null,
      },
    },
    StepThree: {
      filling: [],
      prohibitions: [],
    },
    StepFour: {
      videoUrl: '',
      photos: [],
    },
    StepFive: {
      conditions: [
        {
          id: null,
          weekdays: [],
          // enable: false,
          from: null,
          to: null,
          visitors: {
            min: null,
            max: null,
          },
          price: null,
          fullday: false,
        },
      ],
    },
  }

  private conditions: Condition[] = []

  @Mutation
  private setModel(model: Model) {
    this.model = model
  }

  @Mutation
  private setConditions(conditions: Condition[]) {
    this.conditions = conditions
  }

  @Mutation
  private setEditCondition(condition: any) {
    this.conditions = this.conditions.map((item) => {
      if (item.id === condition.id) {
        return condition
      }
      return item
    })
  }

  @Action
  private createPlace() {
    const features = this.model.StepThree.filling.map((item) => item.id)

    const formData = new FormData()

    const { photos } = this.model.StepFour
    const { placeType } = this.model.StepOne
    const { conditions } = this.model.StepFive
    const managers = [1]

    formData.append('active', String(+this.model.StepOne.active))
    formData.append('show_schedule', String(+this.model.StepOne.show_schedule))
    formData.append('name', this.model.StepOne.name)
    formData.append('description', this.model.StepOne.description)
    formData.append('area', String(this.model.StepOne.area))
    formData.append('capacity', String(this.model.StepOne.capacity))
    formData.append('location_id', this.model.StepOne.address)
    formData.append('place_types[]', placeType)

    for (let i = 0; i < features.length; i++) {
      formData.append('features[]', String(features[i]))
    }

    for (let i = 0; i < conditions.length; i++) {
      for (let j = 0; j < conditions[i].weekdays.length; j++) {
        formData.append(
          `offers[${i}][weekdays][${j}]`,
          String(conditions[i].weekdays[j])
        )
      }
      formData.append(`offers[${i}][from]`, String(conditions[i].from))
      formData.append(`offers[${i}][to]`, String(conditions[i].to))
      formData.append(`offers[${i}][price]`, String(conditions[i].price))
      formData.append(`offers[${i}][fullday]`, String(+conditions[i].fullday))
      formData.append(
        `offers[${i}][visitors][min]`,
        String(conditions[i].visitors.min)
      )
      formData.append(
        `offers[${i}][visitors][max]`,
        String(conditions[i].visitors.max)
      )
    }

    for (let i = 0; i < photos.length; i++) {
      formData.append('images[]', photos[i])
    }

    for (let i = 0; i < managers.length; i++) {
      formData.append('managers[]', String(managers[i]))
    }

    axios
      .post('/api/b2b/v1/places', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        console.log(response)
      })
  }
}
