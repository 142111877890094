import { AxiosInstance, AxiosResponse } from 'axios'
import { Params, Data } from '@/api/types'

import { Site, Filter } from './types'
import { IntegrationWrapper } from '@/api/CommonWrapper/IntegrationWrapper/IntegrationWrapper'

export * from './types'

export class CommonWrapper {
  private readonly baseUrl = '/api/common/v1'
  private readonly axios: AxiosInstance

  public readonly integration: IntegrationWrapper

  constructor(axios: AxiosInstance) {
    this.axios = axios
    this.integration = new IntegrationWrapper(axios)
  }

  private fetch<T>(
    path: string,
    params: Params = {}
  ): Promise<AxiosResponse<T>> {
    return this.axios.get<T>(this.baseUrl + path, { params })
  }

  private post<T>(
    path: string,
    data: Data,
    params: Params = {}
  ): Promise<AxiosResponse<T>> {
    return this.axios.post<T>(this.baseUrl + path, data, { params })
  }

  private put<T>(
    path: string,
    data: Data,
    params: Params = {}
  ): Promise<AxiosResponse<T>> {
    return this.axios.put<T>(this.baseUrl + path, data, { params })
  }

  private patch<T>(
    path: string,
    data: Data,
    params: Params = {}
  ): Promise<AxiosResponse<T>> {
    return this.axios.patch<T>(this.baseUrl + path, data, { params })
  }

  private delete<T>(
    path: string,
    params: Params = {}
  ): Promise<AxiosResponse<T>> {
    return this.axios.delete<T>(this.baseUrl + path, { params })
  }

  public getSitesList(): Promise<Site[]> {
    return this.fetch<Site[]>('/sites').then((r) => r.data)
  }

  public getFiltersList(): Promise<Filter[]> {
    return this.fetch<Filter[]>('/filters').then((r) => r.data)
  }
}
