














































































































import { Component, Vue } from 'vue-property-decorator'
import LandingTitle from './LandingTitle.vue'
import BaseAccordion from '@/components/base/BaseAccordion.vue'

@Component({ name: 'LandingFaq', components: { LandingTitle, BaseAccordion } })
export default class LandingFaq extends Vue {}
