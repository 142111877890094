import axios from '@/api/axios'

class StatististicsService {
  async getStatististics(): Promise<any> {
    try {
      const response = await axios.get('/api/b2b/v1/dashboards/stats')
      return response.data.data
    } catch (error) {
      console.log(error)
      return error.response.data
    }
  }
}

export default new StatististicsService()
