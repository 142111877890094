
























import { Component, Vue } from 'vue-property-decorator'
import AOS from 'aos'
import 'aos/dist/aos.css'

import LandingInfo from './components/LandingInfo.vue'
import LandingOffer from './components/LandingOffer.vue'
import LandingHeader from './components/LandingHeader.vue'
import LandingStat from './components/LandingStat.vue'
import LandingPlatform from './components/LandingPlatform.vue'
import LandingFaq from './components/LandingFaq.vue'
import LandingFooter from './components/LandingFooter.vue'
import LandingBlock from './components/LandingBlock.vue'

import LandingTitle from './components/LandingTitle.vue'
import LandingForm from './components/LandingForm.vue'

@Component({
  name: 'Landing',
  components: {
    LandingTitle,
    LandingOffer,
    LandingInfo,
    LandingHeader,
    LandingStat,
    LandingPlatform,
    LandingFaq,
    LandingForm,
    LandingFooter,
    LandingBlock,
  },
})
export default class LandingPage extends Vue {
  public mounted(): void {
    AOS.init()
  }
}
