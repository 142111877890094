



































import { Component, Prop, Vue } from 'vue-property-decorator'

type ButtonType = 'button' | 'submit'
type ColorType = 'green' | 'purple' | 'gray' | 'white' | 'red'

@Component
export default class BaseButton extends Vue {
  @Prop({ default: 'button' })
  type!: ButtonType

  @Prop({ default: false })
  fluid!: boolean

  @Prop({ default: false })
  large!: boolean

  @Prop({ default: false })
  medium!: boolean

  @Prop({ default: false })
  small!: boolean

  @Prop({ default: false })
  icon!: boolean

  @Prop({ default: false })
  disabled!: boolean

  @Prop({ default: false })
  outline!: boolean

  @Prop()
  classes!: string

  @Prop({ default: 'purple' })
  color!: ColorType

  get isDisabled(): boolean {
    return this.disabled
  }
}
