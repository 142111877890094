import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

import StatististicsService from '@/modules/DashboardsPage/StatististicsService'

@Module({
  namespaced: true,
})
export default class StatististicsStore extends VuexModule {
  private statististics: any | null = null

  @Mutation
  private setStatististics(data: any) {
    this.statististics = data
  }

  @Action({ commit: 'setStatististics' })
  getStatististics(): Promise<any> {
    return StatististicsService.getStatististics()
  }

  get statististicsList(): any {
    return this.statististics
  }
}
