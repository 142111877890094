import store from '@/store'
import { getItem } from '@/utils/persistanceStorage'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import LandingPage from '../modules/LandingPage/LandingPage.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: LandingPage,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../modules/AuthPage/LoginPage.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../modules/AuthPage/RegisterPage.vue'),
    meta: {
      layout: 'auth',
    },
  },

  {
    path: '/my-rooms',
    name: 'MyRooms',
    component: () => import('../modules/MyRoomsPage/MyRoomsPage.vue'),
    meta: {
      layout: 'profile',
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../modules/ProfilePage/ProfilePage.vue'),
    meta: {
      layout: 'profile',
    },
  },

  {
    path: '/statistics',
    name: 'Dashboards',
    component: () => import('../modules/DashboardsPage/DashboardsPage.vue'),
    meta: {
      layout: 'profile',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name !== from.name) {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0, behavior: 'smooth' }
      }
    }
  },
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = getItem('token')

  if (authRequired && !loggedIn) {
    next('/login')
  } else {
    next()
  }
})

export default router
