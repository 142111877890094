import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import PlaceTypesService from '@/core/services/PlaceTypesService'
import { PlaceTypesModel } from '@/core/models/PlaceTypesModel'

@Module({
  namespaced: true,
})
export default class PlaceTypesStore extends VuexModule {
  private placeTypes: PlaceTypesModel[] = []

  @Mutation
  private setPlaceTypes(placeTypes: PlaceTypesModel[]) {
    this.placeTypes = placeTypes
  }

  @Action({ commit: 'setPlaceTypes' })
  getPlaceTypes(): Promise<PlaceTypesModel[]> {
    return PlaceTypesService.getPlaceTypes()
  }

  get placeTypesList(): PlaceTypesModel[] {
    return this.placeTypes
  }
}
