















































































import { Component, Vue } from 'vue-property-decorator'
import BaseIcon from '@/components/base/BaseIcon.vue'

@Component({ name: 'LandingFooter', components: { BaseIcon } })
export default class LandingFooter extends Vue {
  links = [
    { id: 1, title: 'DЕЛИSPACE', url: 'https://delispace.ru/' },
    { id: 2, title: 'DЕЛИEVENT', url: 'https://event.delispace.ru/' },
    { id: 3, title: 'DЕЛИROOM', url: 'https://room.delispace.ru/' },
    { id: 4, title: 'DЕЛИSPORT', url: 'https://sport.delispace.ru/' },
    { id: 5, title: 'DЕЛИOFFICE', url: 'https://office.delispace.ru/' },
  ]
}
