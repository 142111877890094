



























































import { Component, Vue } from 'vue-property-decorator'
import { OfferModel } from '../models/OfferModel'
import breakpoints from '@/plugins/breakpoints'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import LandingTitle from './LandingTitle.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'

@Component({
  name: 'LandingOffer',
  components: { LandingTitle, Swiper, SwiperSlide, BaseIcon },
})
export default class LandingOffer extends Vue {
  breakpoints = breakpoints
  activePoint = 1
  activeObj: OfferModel = new OfferModel()

  swiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 32,
    centeredSlides: true,
    loop: true,
    pagination: {
      el: '.LandingOffer__pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.LandingOffer__next',
      prevEl: '.LandingOffer__prev',
    },
    // breakpoints: {
    //   480: {
    //     slidesPerView: 'auto',
    //     spaceBetween: 32,
    //   },
    //   320: {
    //     slidesPerView: 'auto',
    //     spaceBetween: 60,
    //   },
    // },
  }

  list = [
    {
      id: 1,
      title: 'Бесплатное размещение',
      text: 'на нашем сервисе до конца 2021 г, далее абонентская плата от 99 руб/мес',
      image: '/img/landing/notebook/notebook.png',
      imageMobile: '/img/landing/notebook/notebook-mobile.png',
    },
    {
      id: 2,
      title: 'Наглядная статистика и аналитика',
      text: 'по каждому помещению количество просмотров, звонков и заявок',
      image: '/img/landing/notebook/notebook.png',
      imageMobile: '/img/landing/notebook/notebook-mobile.png',
    },
    {
      id: 3,
      title: 'Подменный номер',
      text: 'защита от спама вашего прямого номера телефона',
      image: '/img/landing/notebook/notebook.png',
      imageMobile: '/img/landing/notebook/notebook-mobile.png',
    },
    {
      id: 4,
      title: 'Бесплатная интеграция расписания',
      text: 'с Google Календарь, Yclients, передача данных по API',
      image: '/img/landing/notebook/notebook.png',
      imageMobile: '/img/landing/notebook/notebook-mobile.png',
    },
    {
      id: 5,
      title: 'Продвижение и реклама (SEO, контекстная реклама, медиа)',
      text: 'ваша задача - только принимать звонки и заявки от клиентов!',
      image: '/img/landing/notebook/notebook.png',
      imageMobile: '/img/landing/notebook/notebook-mobile.png',
    },
    {
      id: 6,
      title: 'Возможность выделить вашу площадку из сотни других',
      text: 'платное приоритетное размещение, рекламные баннеры',
      image: '/img/landing/notebook/notebook.png',
      imageMobile: '/img/landing/notebook/notebook-mobile.png',
    },
  ]

  public created(): void {
    this.change(this.activePoint)
  }

  change(id: number): void {
    this.activePoint = id
    const active = this.list.find((item) => item.id === this.activePoint)

    if (active) {
      this.activeObj = active
    }
  }
}
