import Vue from 'vue'
import Meta from 'vue-meta'
import Vuelidate from 'vuelidate'
import vueBemCn from 'vue-bem-cn'
import { VueMaskDirective } from 'v-mask'
import VueApexCharts from 'vue-apexcharts'
import YmapPlugin from 'vue-yandex-maps'
import { ApiWrapper } from '@/api/ApiWrapper'

import App from './App.vue'
import router from './router'
import store from './store'

// Declare $api in vue.
Vue.prototype.$api = new ApiWrapper()

declare module 'vue/types/vue' {
  interface Vue {
    $api: ApiWrapper
  }
}

import 'animate.css'

Vue.config.productionTip = false
Vue.use(Meta)
Vue.use(Vuelidate)
Vue.use(vueBemCn)
Vue.directive('mask', VueMaskDirective)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

const settings = {
  apiKey: process.env.VUE_APP_API_KEY_YANDEX,
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1',
}

Vue.use(YmapPlugin, settings)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
