import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { RoomModel } from '@/modules/MyRoomsPage/models/RoomModel'
import RoomsService from '@/modules/MyRoomsPage/RoomsService'

@Module({
  namespaced: true,
})
export default class RoomsStore extends VuexModule {
  private rooms: RoomModel[] = []

  @Mutation
  private setRooms(rooms: RoomModel[]) {
    this.rooms = rooms
  }

  @Action({ commit: 'setRooms' })
  getRooms(): Promise<RoomModel[]> {
    return RoomsService.getRooms()
  }

  get roomList(): RoomModel[] {
    return this.rooms
  }
}
